import React, { useState } from 'react'
import Link from 'components/globals/link'
import { useIsMobile } from 'utils/index'

import { useStaticQuery, graphql } from 'gatsby'

import {
    isActiveLink,
} from 'utils'

export default ({
    isOpen,
    closeMenu
}) => {
    const { nodes: menuItems } = useStaticQuery(graphql`
        query {
            siteData {
                menu (
                    id: "TWVudToxNTY"
                ) {
                    id
                    menuItems {
                        nodes {
                            id
                            label
                            url
                            menuFields {
                                backgroundImage {
                                    mediaItemUrl
                                }
                                backgroundColour
                            }
                        }
                    }
                }
            }
        }
    `).siteData.menu.menuItems

    const [hoveredMenuItemId, setHoveredMenuItemId] = useState(null)

    const handleMenuClick = url => {
        if (!url || !~url.indexOf('openContact')) {
            return setTimeout(() => {
                closeMenu()
            }, 100)
        }
    }

    return (
        <div className={`main-menu inverted-cursor ${isOpen ? 'open' : ''}`}>
            <ul className={'bgs'}>
                <div>
                    {menuItems.map(menuItem => {
                        const {
                            id,
                            menuFields
                        } = menuItem

                        return (
                            <li
                                key={id}
                                className={hoveredMenuItemId === id ? 'visible' : ''}
                                style={{
                                    backgroundImage: `url(${menuFields.backgroundImage.mediaItemUrl})`,
                                    backgroundColor: `${menuFields?.backgroundColour}`
                                }}
                            />
                        )
                    })}
                </div>
            </ul>
            <ul className={'menu'}>
                {menuItems.map(menuItem => {
                    const {
                        id,
                        label,
                        url
                    } = menuItem

                    return (
                        <li
                            key={id}
                            onMouseEnter={() => setHoveredMenuItemId(id)}
                            onMouseLeave={() => setHoveredMenuItemId(null)}
                        >
                            <Link
                                to={url}
                                className={'has-cursor-effect'}
                                onClick={() => handleMenuClick(url)}
                            >
                                <div>
                                    <span>
                                        {label}
                                    </span>
                                    <span>
                                        {label}
                                    </span>
                                </div>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
import React, { useContext } from 'react'
import { MainContext } from "context/context"

import Video from './modalTypes/video'

export default ({

}) => {
	const {
        modalData,
        closeModal
    } = useContext(MainContext)

    const {
        type,
        data,
        isVisible
    } = modalData

    let component = null

    switch(type){
        case 'VIDEO':
            component = (
                <Video
                    data={data}
                    close={closeModal}
                    isVisible={isVisible}
                />
            )
            break
        case 'MENU':

            break
        case 'CONTACT':

                break
    }

    return (
        <div 
            className={`modal inverted-cursor ${ isVisible ? 'visible' : '' }`}
            onClick={closeModal}
        >
            <div 
                className={type}
                onClick={e => e.stopPropagation()}
            >
                { component }
            </div>
        </div>
    )
}
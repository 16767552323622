import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from 'context/context'

export default ({

}) => {
    const {
        options: {
            logos
        }
    } = useContext(MainContext)

    const [hasMounted, setHasMounted] = useState(false)
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true)

            setTimeout(() => {
                setIsVisible(false)
            }, 700)
        }, 700)
    }, [])

    return (
        <div className={`site-loader ${isVisible ? 'visible' : ''} ${hasMounted ? 'mounted' : ''}`}>
            <div className={'inner'}>
                <svg
                    className={'cover'}
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 227.5 140.5"
                    style={{
                        enableBackground: 'new 0 0 227.5 140.5'
                    }}
                    xmlSpace="preserve"
                    preserveAspectRatio={'none'}
                >
                    <path className="st0" d="M0,140.5c0,0,15.5-19.3,61.1-21c45.6-1.8,62,17.3,89.1,18.2s58.5-14.2,77.3-23.5c0-25.3,0-114.2,0-114.2H0 V140.5z" />
                </svg>
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 206.84 128.34"
                    className='logo'
                >
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g>
                            <path className="cls-1" d="m104.83,9.62c-3.98-4.76-10.27-7.59-16.84-7.59s-13.88,4.29-18.47,10.93c-7.07,10.22-17.89,38.19-21.89,48.18-.56,1.39-1.26,3.01-1.26,3.01-8.12-1.97-15.48-7.11-19.39-13.7-4.38-7.37-6.7-17.39-6.9-29.77-.11-7,.61-14.17.9-17.31H1.08C.75,6.48-.08,14,0,19.47c.3,18.05,4.01,32.53,11.04,43.04,6.53,9.75,16.6,16.69,27.89,19.39,0,0-16.79,40.06-19.53,46.44h22.38c2.5-5.91,17.85-46.39,17.85-46.39,10.03-2.34,20.56-7.93,28.01-14.91,13.46-12.61,22.09-29.1,21.84-44.38-.08-4.84-1.78-9.59-4.66-13.04Zm-20.52,36.51c-9.12,12.82-17.24,16.12-17.24,16.12l7.14-17.34c6.19-15.07,11.07-25.88,15.57-25.88,2.55,0,4.05,1.99,4.11,5.62.07,4.51-1.66,10.35-9.58,21.48Z" />
                            <path className="cls-1" d="m201.87,51.64c5.98-15.2,6.57-29.24,1.74-37.47-5.12-8.72-14.74-14.18-26.72-14.18-12.97,0-24.63,5.13-34.05,12.51-10.01,7.84-17.49,18.15-21.35,25.62-6.2,11.99-12.09,28.61-12.71,44.45-.57,14.61,2.36,26.12,8.75,33.93,6.41,7.84,15.9,11.82,28.22,11.82,13.45,0,25.28-4.31,35.18-12.81,9.86-8.47,16.49-20.67,21.04-35l-20.55-1.38c-3.13,9.31-7.13,17.25-13.22,23.11-6.03,5.8-12.58,8.74-19.49,8.74-6.37,0-11.03-2.5-14.41-7.49-3.38-5-4.92-11.94-4.58-20.64.41-10.4,2.39-19.98,6.37-30.03,3.97-10.03,9.35-18.44,16-25,6.58-6.49,14.38-9.78,21.51-9.78,6.46,0,11.13,2.49,12.98,8.59,1.87,6.15-1.42,17.64-6.5,25h21.79Z" />
                        </g>
                    </g>
                </svg>
            </div>
        </div >
    )
}
import React, { useContext, useState } from 'react'
import { MainContext } from "context/context"
import { emailIsValid } from "utils"
import Button from "components/globals/button.jsx"

import addToMailchimp from 'gatsby-plugin-mailchimp'
import Swal from 'sweetalert2'

export default () => {
    const {
        copy
    } = useContext(MainContext).options.subscribe[0]

    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleError = message => {
        setError(message)

        setTimeout(() => {
            setError(null)
        }, 2000)
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        if (emailIsValid(email)) {
            setLoading(true)

            addToMailchimp(email).then(data => {
                const {
                    result,
                } = data

                setLoading(false)

                if (result === 'error') {
                    handleError(`You appear to be already subscribed.`)
                } else {

                    Swal.fire({
                        icon: 'success',
                        title: `You're subscribed!`,
                        text: `We've added you to our mailing list.`,
                    })
                }
            })
        } else {
            handleError(`That email address doesn't look valid.`)
        }
    }

    return (
        <div className="subscribe">
            <h4 dangerouslySetInnerHTML={{ __html: copy }} />
            <form className={loading ? 'loading' : ''} onSubmit={handleFormSubmit}>
                <div
                    className="join-button"
                >
                    <Button
                        label="Join"
                        to="/"
                    />
                </div>
                <input
                    type="email"
                    value={email}
                    placeholder={`Email address`}
                    onChange={e => { setEmail(e.target.value) }}
                />
                <div className={`validation ${error ? 'has-error' : ''}`}>
                    <i className={'fa fa-exclamation-triangle'} />
                    <span>
                        {error || ''}
                    </span>
                </div>
            </form>
        </div>
    )
}
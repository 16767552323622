import React, { useContext } from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { MainContext } from "context/context"

export default ({
    children,
    to,
    className,
    onClick,
    innerRef
}) => {
	const {
		setOpenMenuType
    } = useContext(MainContext)
    
    const isExternal = to.startsWith('http')

    const commonProps = {
        className,
        onClick,
    }

    if(isExternal){
        return (
            <a 
                href={to}
                {...commonProps}
                target="_blank"
            >
                { children }
            </a>
        )
    }

    const handleClick = e => {
        if(onClick){
            onClick(e)
        }
        
        if(to.indexOf('openContact') !== -1){
            e.preventDefault()

            setTimeout(() => {
                setOpenMenuType('contact')
            },100)
        }
    }

    return (
        <TransitionLink 
            to={to} 
            exit={{ 
                length: 0.5,
                trigger: () => {
                    setTimeout(() => {
                        if(typeof window !== 'undefined'){
                            window.scrollTo({top: 0})
                        }
                    },300)
                }
            }} 
            entry={{ 
                length: 0.3,
                appearAfter: 0.3
            }}
            className={className}
            onClick={handleClick}
            preventScrollJump
            innerRef={innerRef}
        >
            { children }
        </TransitionLink>
    )
}
import React, { useRef, useEffect, useContext } from 'react'
import { MainContext } from "context/context"

export default ({
    close,
    data,
    isVisible
}) => {
    const videoRef = useRef(null)

    const {
        options: {
            showreel
        }
    } = useContext(MainContext)

    useEffect(() => {
        if (!isVisible && videoRef.current) {
            videoRef.current.pause()
            videoRef.current.currentTime = 0
        }

        if (isVisible) {
            setTimeout(() => {
                if (videoRef.current) {
                    videoRef.current.play()
                }
            }, 1000)
        }

        if (typeof document !== 'undefined') {
            document.body.style.overflow = isVisible ? 'hidden' : 'auto'
        }
    }, [isVisible])

    const videoSrc = data === 'SHOWREEL' ? showreel.mediaItemUrl : data ? data.mediaItemUrl : null

    return (
        <>
            <div className="video-container" onClick={e => e.stopPropagation()}>
                {videoSrc &&
                    <video
                        ref={ref => videoRef.current = ref}
                        controls
                        preload="auto"
                        data-setup='{}'
                        key={typeof data === 'string' ? data : data.mediaItemUrl}
                    >
                        <source src={videoSrc} type="video/mp4" />
                    </video>
                }
            </div>
        </>
    )
}
import React, { useContext, useState, useEffect } from "react"
import { MainContext } from "context/context"
import 'vendor/hamburgers.css'
import Link from 'components/globals/link'
import Menu from 'components/globals/menu'
import ContactModal from 'components/globals/contactModal'
import SiteLoader from 'components/globals/siteLoader'

import { useIsMobile, useScrollDirection } from 'utils'

export default ({
	location
}) => {
	const {
		options,
		isHeaderInverted,
		hasScrolled,
		checkInvertedHeaderOffsets,
		isHeaderDeepPadded,
		openMenuType,
		setOpenMenuType,
		closeModal,
		modalData
	} = useContext(MainContext)

	const {
		logos,
		mobileLogos
	} = options

	useEffect(() => {
		setTimeout(() => {
			checkInvertedHeaderOffsets()
		}, 1000)
	}, [location])

	useEffect(() => {
		setTimeout(() => {
			setHasMounted(true)
		}, 700)
	}, [])

	const isMobile = useIsMobile()

	const [hasMounted, setHasMounted] = useState(false)

	const scrollDirection = useScrollDirection({
		initialDirection: null,
		thresholdPixels: 100,
	})

	const modalVisible = modalData && modalData.isVisible

	let headerClassName = 'header'

	if (isHeaderInverted || openMenuType || modalVisible) {
		headerClassName += ' inverted'
	}

	if (isHeaderDeepPadded && !isMobile) {
		headerClassName += ' deep-padded'
	}

	if (hasScrolled) {
		headerClassName += ' has-scrolled'
	}

	const closeMenu = () => setOpenMenuType(null)

	return (
		<>
			<SiteLoader />
			<div
				className={`header-container ${hasMounted ? 'mounted' : ''}`}
			>
				<Menu
					isOpen={openMenuType === 'menu'}
					closeMenu={closeMenu}
				/>
				<ContactModal
					isOpen={openMenuType === 'contact'}
				/>
				<header className={`${headerClassName} ${(scrollDirection === 'down' && openMenuType !== 'contact') ? 'hide-nav' : ''}`}>
					<div className="container">
						<div className="content">
							<Link
								to={'/'}
								className={'logo has-cursor-effect'}
								onClick={closeMenu}
							>
								<img alt={'YC Logo'} className="normal" src={logos.standard.sourceUrl} />
								<img alt={'YC Inverted Logo'} className="inverted" src={logos.inverted.sourceUrl} />
							</Link>
							<Link
								to={'/'}
								className={'logo mobile has-cursor-effect'}
								onClick={closeMenu}
							>
								<img alt={'YC Logo'} className="normal" src={mobileLogos.standard.sourceUrl} />
								<img alt={'YC Inverted Logo'} className="inverted" src={mobileLogos.inverted.sourceUrl} />
							</Link>
							<div />
							<div className={'controls'}>
								<Link
									to={'/yc-labs'}
									className={`button contact labs has-cursor-effect ${(openMenuType || modalVisible) ? 'hidden' : ''}`}
								>
									YC Labs™
								</Link>
								<a
									to={'/contact'}
									className={`button contact has-cursor-effect ${(openMenuType || modalVisible) ? 'hidden' : ''}`}
									onClick={() => setOpenMenuType('contact')}
								>
									Contact
								</a>
								<button
									className={`hamburger hamburger--spin js-hamburger has-cursor-effect ${(openMenuType || modalVisible) ? 'is-active' : ''}`}
									onClick={() => {
										setOpenMenuType((openMenuType || modalVisible) ? null : 'menu')

										if (modalVisible) {
											closeModal()
										}
									}}
								>
									<div className="hamburger-box">
										<div className="hamburger-inner" />
									</div>
								</button>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	)



}

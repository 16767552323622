import moment from 'moment'
import { useState, useEffect, useRef } from 'react'
import { throttle } from 'lodash'

export * from './cursor'

export const formatDate = date => {
	return moment(date).format('Do MMMM, YYYY')
}

export const isActiveLink = (location, url) => {
	const { pathname } = location

	let _url = url.replace('/', '')

	const _pathname = pathname.replace('/', '')
	const isActive = _url && _pathname && _pathname.indexOf(_url) !== -1

	return isActive || (!_pathname && !_url)
}

export const nl2br = str => {
	if (typeof str === 'undefined' || str === null) {
		return ''
	}

	const breakTag = '<br />'

	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

export const stripHtml = str => {
	return str.replace(/<[^>]*>?/gm, '')
}

export const isMobile = typeof window !== 'undefined' ? window.innerWidth <= 500 : false
export const isMobileMenu = typeof window !== 'undefined' ? window.innerWidth <= 1024 : false

export const emailIsValid = email => {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase())
}

export const useIsMobile = (size = 500) => {
	const _window = typeof window !== 'undefined' ? window : null

	const [_isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			if (_window) {
				setIsMobile(_window.screen.width <= size)
			}
		}, 5)
	}, [_window])

	return _isMobile
}

export const useIsTablet = (size = 1024) => {
	const _window = typeof window !== 'undefined' ? window : null

	const [_isTablet, setIsTablet] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			if (_window) {
				setIsTablet(_window.screen.width <= size)
			}
		}, 5)
	}, [_window])

	return _isTablet
}

export const getReadLength = text => {
	const regex = /(<([^>]+)>)/ig
	const wordsWithoutHtml = text.replace(regex, "")

	const wordCount = wordsWithoutHtml.split(' ').length

	const minutes = wordCount / 250

	return Math.round(minutes) + ' minute'
}

export const useWhyDidYouUpdate = (name, props) => {
	const previousProps = useRef()

	useEffect(() => {
		if (previousProps.current) {
			const allKeys = Object.keys({ ...previousProps.current, ...props })
			const changesObj = {}
			allKeys.forEach(key => {
				if (previousProps.current[key] !== props[key]) {
					changesObj[key] = {
						from: previousProps.current[key],
						to: props[key]
					}
				}
			})
		}

		previousProps.current = props
	})
}

export const shuffle = array => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		[array[i], array[j]] = [array[j], array[i]]
	}
	return array
}

const SCROLL_UP = "up"
const SCROLL_DOWN = "down"

export const useScrollDirection = ({
	initialDirection,
	thresholdPixels,
	off
} = {}) => {
	const [scrollDir, setScrollDir] = useState(initialDirection)

	useEffect(() => {
		const threshold = thresholdPixels || 0
		let lastScrollY = window.pageYOffset
		let ticking = false

		const updateScrollDir = () => {
			const scrollY = window.pageYOffset

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				// We haven't exceeded the threshold
				ticking = false
				return
			}

			setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP)
			lastScrollY = scrollY > 0 ? scrollY : 0
			ticking = false
		}

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir)
				ticking = true
			}
		}

		/**
		 * Bind the scroll handler if `off` is set to false.
		 * If `off` is set to true reset the scroll direction.
		 */
		!off
			? window.addEventListener("scroll", onScroll)
			: setScrollDir(initialDirection)

		return () => window.removeEventListener("scroll", onScroll)
	}, [initialDirection, thresholdPixels, off])

	return scrollDir
}

export const useThrottledHandleScroll = (callback, interval = 100) => {
	return useRef(throttle(callback, interval))
}

const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a))
export const invlerp = (x, y, a) => clamp((a - x) / (y - x))
import React, { useContext, useState } from 'react'
import { MainContext } from "context/context"

export default ({

}) => {
    const {
        options: {
            footerCopy,
            logos
        },
        hasScrolledPastFooter,
        acknowledgementVisible
    } = useContext(MainContext)

    if (!acknowledgementVisible) {
        return null
    }

    return (
        <div className={`acknowledgement-screen ${hasScrolledPastFooter ? 'visible' : ''}`}>
            <h1 dangerouslySetInnerHTML={{ __html: footerCopy }} />
        </div>
    )
}
import React, { 
	useState, 
	useEffect, 
	useRef, 
	useCallback
} from "react"

import { useStaticQuery, graphql } from "gatsby"
import { throttle } from 'lodash'
import { Demo1 } from 'utils'

//import LocomotiveScroll from 'locomotive-scroll'
const LocomotiveScroll = typeof window !== 'undefined' ? require('locomotive-scroll') : () => null

export const MainContext = React.createContext()

const Provider = ({
	children
}) => {

	const componentRefs = useRef([])
	const [visibleComponent, setVisibleComponent] = useState('')
	const [modalData, setModalData] = useState(false)
	const [isHeaderInverted, setIsHeaderInverted] = useState(false)
	const [isHeaderDeepPadded, setIsHeaderDeepPadded] = useState(false)
	const [hasScrolledPastFooter, setHasScrolledPastFooter] = useState(false)
	const [hasScrolled, setHasScrolled] = useState(false)
	const [openMenuType, setOpenMenuType] = useState(null)
	const [acknowledgementVisible, setAcknowledgementVisible] = useState(false)

	const invertedHeaderSections = useRef([])
	const deepPaddedHeaderSections = useRef([])

	const addInvertedHeaderSection = useCallback(ref => {
		const { current } = invertedHeaderSections

		if (current.indexOf(ref) === -1) {
			current.push(ref)
		}

		invertedHeaderSections.current = current

		checkInvertedHeaderOffsets()
	}, [])

	const addDeepPaddedHeaderSection = useCallback(ref => {
		const { current } = deepPaddedHeaderSections

		if (current.indexOf(ref) === -1) {
			current.push(ref)
		}

		deepPaddedHeaderSections.current = current
	}, [])

	const recalcLocomotive = useCallback(() => {
		if (window.scrollerInstance) {
			window.scrollerInstance.update()
		}
	}, [])


	const isHeaderInvertedRef = useRef(isHeaderInverted)
	isHeaderInvertedRef.current = isHeaderInverted

	const isHeaderDeepPaddedRef = useRef(isHeaderDeepPadded)
	isHeaderDeepPaddedRef.current = isHeaderDeepPadded

	const hasScrolledRef = useRef(hasScrolled)
	hasScrolledRef.current = hasScrolled

	const hasScrolledPastFooterRef = useRef(hasScrolledPastFooter)
	hasScrolledPastFooterRef.current = hasScrolledPastFooter

	const _window = typeof window !== 'undefined' ? window : null

	let isMobile = false
	let isTablet = false

	if (_window && _window.innerWidth <= 500) {
		isMobile = true
	}

	if (_window && _window.innerWidth <= 1024) {
		isTablet = true
	}

	const initNewPage = () => {
		if (window.scrollerInstance) {
			window.scrollerInstance.destroy()

			setTimeout(() => {
				setupLocomotive()

				setTimeout(() => {
					if(window.cursorHandler){
						window.cursorHandler.attachListeners()
					}
				},2000)
			}, 10)
		}
	}

	const _document = typeof document !== 'undefined' ? document : null

	const setupLocomotive = () => {
		if (_document && LocomotiveScroll) {
			setTimeout(() => {
				window.scrollerInstance = new LocomotiveScroll.default({
					el: document.querySelector('[data-scroll-container]'),
					smooth: true,
					repeat: true,
					resetNativeScroll: false,
					multiplier: 1
				})

				window.scrollerInstance.on('scroll', debouncedHandleScroll.current)

				setTimeout(() => {
					debouncedHandleScroll.current()
				}, 250)

				setTimeout(() => {
					window.scrollerInstance.update()
				}, 500)
			}, 100)
		}
	}

	useEffect(() => {
		if (_window && !isMobile) {
			window.cursorHandler = new Demo1()
		}

		setTimeout(() => {
			setupLocomotive()
		}, 700)
	}, [_document])

	const checkInvertedHeaderOffsets = pos => {
		const scrollOffset = (pos && pos.scroll) ? pos.scroll.y : 0

		const hasScrolledOffset = 100

		if (_window) {
			if (scrollOffset > hasScrolledOffset && !hasScrolledRef.current) {
				setHasScrolled(true)
			} else if (scrollOffset <= hasScrolledOffset && hasScrolledRef.current) {
				setHasScrolled(false)
			}
		}

		// Inverts
		const invertRefs = invertedHeaderSections.current
		let _isHeaderInverted = false

		invertRefs.forEach(ref => {
			const offsets = ref.getBoundingClientRect()

			if(scrollOffset > hasScrolledOffset && !offsets.top){
				// probably a no-longer-visible locomotive ref
				return 
			}

			const isOverSection = offsets.top < 120 && (offsets.top + offsets.height) > 120

			if (isOverSection) {
				_isHeaderInverted = true
			}
		})

		if (_isHeaderInverted !== isHeaderInvertedRef.current) {
			setIsHeaderInverted(_isHeaderInverted)
		}

		// Deep padded
		const deepPaddedRefs = deepPaddedHeaderSections.current
		let _isHeaderDeepPadded = false

		deepPaddedRefs.forEach(ref => {
			const offsets = ref.getBoundingClientRect()

			const isOverSection = offsets.top < 120 && (offsets.top + offsets.height) > 120

			if (isOverSection) {
				_isHeaderDeepPadded = true
			}
		})

		if (_isHeaderDeepPadded !== isHeaderDeepPaddedRef.current) {
			setIsHeaderDeepPadded(_isHeaderDeepPadded)
		}

		// Past footer?
		const footer = document.querySelector('.footer-outer')

		if(footer){
			const footerBounding = footer.getBoundingClientRect()

			const screenMiddle = window.innerHeight / 2

			if(footerBounding.top < screenMiddle && !hasScrolledPastFooterRef.current){
				setHasScrolledPastFooter(true)
			}else if(footerBounding.top > screenMiddle && hasScrolledPastFooterRef.current){
				setHasScrolledPastFooter(false)
			}
		}
	}

	const debouncedHandleScroll = useRef(throttle(checkInvertedHeaderOffsets, 200))

	const openModal = props => {
		setModalData({
			...props,
			isVisible: true
		})
	}

	const closeModal = () => {
		setModalData({
			...modalData,
			isVisible: false
		})
	}

	const doScrollEvent = callback => {
		if(isTablet){
			return window.addEventListener('scroll', e => {
				callback(window.scrollY)
			})
		}
		return window.scrollerInstance.on('scroll', pos => {
			pos.delta && callback(pos.delta.y)
		})
	}

	const optionsQuery = useStaticQuery(graphql`
		query optionsQuery {
			siteData {
				options {
					options {
						logos {
							standard {
								sourceUrl
							}
							inverted {
								sourceUrl
							}
						}
						mobileLogos {
							standard {
								sourceUrl
							}
							inverted {
								sourceUrl
							}
						}
						socials {
							type
							url
						}
						columns {
							title
							copy
							showSocials
						}
						subscribe {
							copy
						}
						footerCopy
						showreel {
							mediaItemUrl
						}
						genericCta {
							title
							copy
						}
					}
				}
			}
		}
	`)

	const { options } = optionsQuery.siteData.options

	return (
		<MainContext.Provider
			value={{
				options,
				openModal,
				closeModal,
				modalData,
				checkInvertedHeaderOffsets,
				isHeaderInverted,
				isHeaderDeepPadded,
				hasScrolled,
				componentRefs,
				isMobile,
				doScrollEvent,
				initNewPage,
				openMenuType,
				setOpenMenuType,
				setVisibleComponent,
				visibleComponent,
				hasScrolledPastFooter,
				addInvertedHeaderSection,
				addDeepPaddedHeaderSection,
				recalcLocomotive,
				acknowledgementVisible,
				setAcknowledgementVisible
			}}
		>
			{children}
		</MainContext.Provider>
	)
};

export default ({ element }) => (
	<Provider>
		{element}
	</Provider>
);
import React, { useEffect, useRef, useContext } from 'react'
import Link from 'components/globals/link'
import { MainContext } from 'context/context'
import { isMobile } from 'utils/index'

const ButtonCtrl = typeof window !== 'undefined' ? require('utils/buttonCtrl') : () => null

export default ({
    label,
    url,
    onClick,
    type = 'link',
    inverted,
    inverted2,
    compact,
    icon,
    iconRight,
    noMagnetism
}) => {
    const {
        setOpenMenuType,
        openModal
    } = useContext(MainContext)

    const elementRef = useRef(null)

    useEffect(() => {
        let interval = null

        if (ButtonCtrl && !isMobile) {
            setTimeout(() => {
                if (elementRef.current) {
                    const buttonInstance = new ButtonCtrl.default(elementRef.current, noMagnetism)

                    interval = setInterval(() => {
                        buttonInstance.calculateSizePosition()
                    }, 1000)
                }
            }, 2000)
        }

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [ButtonCtrl, elementRef.current])

    const buttonLabel = label || 'Read more'

    let className = `button has-cursor-effect force-cursor-${(inverted || inverted2) ? 'white' : 'black'}`

    if (inverted) {
        className += ' inverted'
    }

    if (inverted2) {
        className += ' inverted2'
    }

    if (!label) {
        className += ' icon-only'
    }

    if (compact) {
        className += ' compact'
    }

    const iconPrefix = icon ? (iconRight || icon === 'plus') ? 'fe fe-' : 'fa fa-' : ''

    const buttonContent = (
        <>
            <div className="button__filler" />
            <span className="button__text">
                <span className="button__text-inner">
                    {(icon && !iconRight) &&
                        <i className={`${iconPrefix}${icon}`} />
                    }
                    {buttonLabel}
                    {(icon && iconRight) &&
                        <i className={`right ${iconPrefix}${icon}`} />
                    }
                </span>
            </span>
        </>
    )

    const _onClick = e => {
        if (onClick) {
            onClick(e)
        }

        if (url) {
            if (url.indexOf('openContact') !== -1) {
                e.preventDefault()

                setOpenMenuType('contact')
            }else if(url.indexOf('openShowreel') !== -1){
                openModal({
                    type: 'VIDEO',
                    data: 'SHOWREEL'
                })
            }
        }
    }

    const commonProps = {
        className,
        onClick: _onClick,
        ref: ref => elementRef.current = ref
    }

    if (type === 'button' || !url) {
        return (
            <button
                {...commonProps}
            >
                {buttonContent}
            </button>
        )
    } else if (url.startsWith('https')) {
        return (
            <a
                href={url}
                {...commonProps}
                target={'_blank'}
            >
                {buttonContent}
            </a>
        )
    } else {
        return (
            <Link
                to={url}
                {...commonProps}
                innerRef={ref => elementRef.current = ref}
            >
                {buttonContent}
            </Link>
        )
    }
}

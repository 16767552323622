import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from "context/context"
import Link from "components/globals/link"
import Subscribe from "components/globals/subscribe"
import { useIsMobile } from "utils"

export default () => {
	const _isMobile = useIsMobile()
	const [forcedMarginBottom, setForcedMarginBottom] = useState(null)

	const {
		options,
		acknowledgementVisible
	} = useContext(MainContext)

	useEffect(() => {
		if(_isMobile){
			const scaleFactor = 500 / window.screen.width
			const screenHeight = window.screen.height * scaleFactor

			setForcedMarginBottom(screenHeight)
		}
	}, [_isMobile])

	const socials = (
		<div>
			{options.socials.map((social, indexTwo) => {
				return (
					<Link to={social.url} key={indexTwo}>
						<i className={`fa fa-${social.type} social-link`} />
					</Link>
				)
			})}
		</div>
	)

	return (
		<>
			<footer 
				data-scroll 
				key={'footer'} 
				className={'footer inverted-cursor'}
				style={{
					marginBottom: forcedMarginBottom ? forcedMarginBottom+'px' : acknowledgementVisible ? undefined : '0px'
				}}
			>
				<div className={'container deep'}>
					<Subscribe />
					<ul className="footer-columns">
						{options.columns.map((option, index) => {
							const {
								title,
								copy,
								showSocials,
							} = option

							return (
								<li key={index}>
									<h4>{title}</h4>
									<div key={index} className={'copy medium'}>
										<span dangerouslySetInnerHTML={{ __html: copy }}/>
									</div>
									{!_isMobile && showSocials && socials }
								</li>
							)
						})}
						{_isMobile && socials}
					</ul>
				</div>
				{/*options.footerCopy &&
					<div className="footer-copy">
						<div className="container deep" dangerouslySetInnerHTML={{ __html: options.footerCopy }} />
					</div>
					*/}
			</footer>
		</>
	)
}

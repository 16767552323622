import React, { useState, useContext, useRef, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from 'context/context'
import Loader from 'components/globals/loader'

import Button from 'components/globals/button'
import { cloneDeep } from 'lodash'

import TextareaAutosize from 'react-textarea-autosize'
import { useMutation } from '@apollo/client'

import Swal from 'sweetalert2'

import { 
    SUBMIT_FORM
} from '../../graphql/queries'

export default ({
    isOpen,
}) => {
    const {
        form,
    } = useStaticQuery(graphql`
		query {
			siteData {
				form (
                    id: 5335
                    idType: DATABASE_ID
                ) {
                    databaseId
                    formFields {
                        rows {
                            columns {
                                label
                                type
                                options
                            }
                        }
                        buttonLabel 
                    }
                }
			}
		}
    `).siteData

    const {
        options,
    } = useContext(MainContext)

    const [submitForm] = useMutation(SUBMIT_FORM)

    const [formValues, setFormValues] = useState({
        'Preferred contact': 'Email'
    })
    const formValuesRef = useRef(formValues)
    formValuesRef.current = formValues

    const inputRefs = useRef([])

    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState(null)

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                if (inputRefs.current.length) {
                    inputRefs.current[0].focus()
                }
            }, 300)
        }
    }, [isOpen])

    const handleChange = (key, value) => {
        const _formValues = cloneDeep(formValuesRef.current)

        _formValues[key] = value

        setFormValues(_formValues)
    }

    const handleSubmit = e => {
        e.preventDefault()

        const columnKeys = []

        form.formFields.rows.forEach(row => {
            row.columns.forEach(column => {
                columnKeys.push(column.label)
            })
        })

        let error = null

        columnKeys.some(key => {
            if(~key.toLowerCase().indexOf('optional')){
                return 
            }
            if(!formValuesRef.current[key]){
                error = true
                return true
            }
        })

        if(error){
            setResponse({
                type: 'error',
                message: `One or more fields are missing.`
            })

            return setTimeout(() => {
                setResponse(null)
            },3000)
        }

        const _values = JSON.stringify(formValuesRef.current)

        setIsLoading(true)

        submitForm({
            variables: {
                data: _values,
            },
        }).then(response => {
            console.log(response)
        }).catch(error => {
            console.log(error)
        })

        Swal.fire({
            icon: 'success',
            title: 'Thanks for that',
            text: `We've received your submission, and will be in touch shortly.`,
        })

        setResponse({
            type: 'success',
            message: `Thanks for that! We've received your submission.`
        })

        setTimeout(() => {
            setFormValues({})
            setIsLoading(false)
        },1000)
    }

    let inputIncrementer = -1

    return (
        <dialog className={`contact-modal inverted-cursor ${isOpen ? 'open' : ''}`}>
            <div className={'inner'}>
                <form onSubmit={handleSubmit}>
                    {isLoading ?
                        <Loader visible={false} />
                        : null}
                    <h3>
                        Send us a message
                    </h3>
                    <div className={`cover-loader ${isLoading ? 'loading' : ''}`}>
                        <ul className={'rows'}>
                            {form.formFields.rows.map(({ columns }, index) => {
                                return (
                                    <li key={index} className={`row columns-${columns.length}`}>
                                        { columns.map((column, columnIndex) => {
                                            const {
                                                label,
                                                options,
                                                type
                                            } = column

                                            let component = null

                                            switch (type) {
                                                case 'text':
                                                case 'email':
                                                    component = (
                                                        <input
                                                            type={type}
                                                            placeholder={label}
                                                            value={formValues[label] || ''}
                                                            onChange={e => handleChange(label, e.target.value)}
                                                            ref={ref => {
                                                                inputIncrementer++
                                                                inputRefs.current[inputIncrementer] = ref
                                                            }}
                                                        />
                                                    )
                                                    break
                                                case 'textarea':
                                                    component = (
                                                        <TextareaAutosize
                                                            placeholder={label}
                                                            value={formValues[label] || ''}
                                                            onChange={e => handleChange(label, e.target.value)}
                                                        />
                                                    )
                                                    break
                                                case 'radio':
                                                    const _options = options.split(/\n/).filter(Boolean).map(option => option.trim())

                                                    component = (
                                                        <div className={'radios'}>
                                                            <span dangerouslySetInnerHTML={{ __html: label + ':' }} />
                                                            <div className={'options'}>
                                                                {_options.map((option, optionIndex) => {
                                                                    return (
                                                                        <label key={optionIndex}>
                                                                            <input
                                                                                type={'radio'}
                                                                                name={label}
                                                                                checked={formValues[label] === option}
                                                                                onChange={() => handleChange(label, option)}
                                                                            />
                                                                            <span>
                                                                                {option}
                                                                            </span>
                                                                        </label>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                                    break
                                            }

                                            return (
                                                <div key={columnIndex}>
                                                    { component}
                                                </div>
                                            )
                                        })}

                                    </li>
                                )
                            })}
                        </ul>
                        <div className={'bottom-row'}>
                            <div className={`validate-response ${response ? 'visible' : ''}`}>
                                {response &&
                                    <>
                                        <i className={`fe fe-${ response.type === 'success' ? 'check' : 'x' }`} />
                                        <span>
                                            { response.message }
                                        </span>
                                    </>
                                }
                            </div>
                            <Button
                                label={form.formFields.buttonLabel}
                                icon={'check'}
                                iconRight
                            />
                        </div>
                    </div>
                </form>
                <aside>
                    <h3>
                        Contact us
                    </h3>
                    <ul>
                        {options.columns.map((option, index) => {
                            const {
                                title: rowTitle,
                                copy
                            } = option

                            return (
                                <li key={index}>
                                    <h4>
                                        {rowTitle}
                                    </h4>
                                    <div className={'copy small'} dangerouslySetInnerHTML={{ __html: copy }} />
                                </li>
                            )
                        })}
                    </ul>
                </aside>
            </div>
        </dialog>
    )
}
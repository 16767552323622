import React from 'react'

import Header from './header'
import Footer from './footer'
import Modal from './modal'
import AcknowledgementScreen from './acknowledgementScreen'

export default ({ children, location }) => {

	return (
		<>
			<div className="page-container">
				<Header location={location} />
				<main data-scroll-container>
					{children}
					<div className={'footer-outer'} data-scroll-section>
						<Footer key={'footer'} />
					</div>
				</main>
				<Modal />
			</div>
			<AcknowledgementScreen />
		</>
	)
}
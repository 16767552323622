import gql from 'graphql-tag'

export const SUBMIT_FORM = gql`
    mutation SUBMIT_FORM (
        $data: String
        $title: String
    ) {
        submitForm (
            input: {
                data: $data
                title: $title
                clientMutationId: "uniqueId"
            }
        ) {
            success
        }
    }
`
// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-page-js": () => import("./../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-single-js": () => import("./../../src/templates/single.js" /* webpackChunkName: "component---src-templates-single-js" */),
  "component---src-templates-single-project-js": () => import("./../../src/templates/singleProject.js" /* webpackChunkName: "component---src-templates-single-project-js" */)
}


import { TweenMax } from 'gsap/all'

const Flickity = typeof window !== "undefined" ? require("flickity") : () => null

export class Demo1 {
	constructor() {
		this.initDemo()
	}

	attachChildListeners(parent) {
		const handleMouseEnter = this.handleMouseEnter
		const handleMouseLeave = this.handleMouseLeave

		parent.querySelectorAll(".has-cursor-effect").forEach(el => {
			el.addEventListener("mouseenter", handleMouseEnter)
			el.addEventListener("mouseleave", handleMouseLeave)
		})
	}

	handleMouseEnter = (el) => {
		this.enlargeCursorTween.play()
	}

	handleMouseLeave = () => {
		this.enlargeCursorTween.reverse()
	}

	attachListeners() {
		setTimeout(() => {
			document.querySelectorAll('.flickity-enabled').forEach(plane => {
				const instance = Flickity.data(plane)

				instance.on('dragMove', (e, pointer, otherThing) => {
					this.clientX = e.clientX
					this.clientY = e.clientY
				})
			})
		}, 5000)

		const handleMouseEnter = this.handleMouseEnter
		const handleMouseLeave = this.handleMouseLeave

		const cursorWrapper = document.getElementById('cursor-wrapper')

		const handleInvertMouseEnter = () => {
			cursorWrapper.classList.add('inverted')
		}

		const handleInvertMouseLeave = () => {
			cursorWrapper.classList.remove('inverted')
		}

		const handleHeaderInvertMouseEnter = (el) => {
			if (el.srcElement.classList.contains('inverted')) {
				cursorWrapper.classList.remove('force-non-invert')
			} else {
				cursorWrapper.classList.add('force-non-invert')
			}

			cursorWrapper.classList.add('inverted')
		}

		const handleHeaderInvertMouseLeave = (el) => {
			if (el.srcElement.classList.contains('inverted')) {
				cursorWrapper.classList.remove('force-non-invert')
			} else {
				cursorWrapper.classList.add('force-non-invert')
			}

			cursorWrapper.classList.remove('inverted')
		}

		const handleForceCursorBlackEnter = () => {
			cursorWrapper.classList.add('force-non-invert')
		}

		const handleForceCursorBlackLeave = () => {
			cursorWrapper.classList.remove('force-non-invert')
		}

		const handleForceCursorWhiteEnter = () => {
			cursorWrapper.classList.add('force-invert')
		}

		const handleForceCursorWhiteLeave = () => {
			cursorWrapper.classList.remove('force-invert')
		}

		const handleDragCursorEnter = () => {
			cursorWrapper.classList.add('show-drag-cursor')
		}

		const handleDragCursorLeave = () => {
			cursorWrapper.classList.remove('show-drag-cursor')
		}

		document.querySelectorAll(".has-cursor-effect").forEach(el => {
			el.addEventListener("mouseenter", handleMouseEnter)
			el.addEventListener("mouseleave", handleMouseLeave)
		})

		document.querySelectorAll(".header").forEach(el => {
			el.addEventListener("mouseenter", handleHeaderInvertMouseEnter)
			el.addEventListener("mouseleave", handleHeaderInvertMouseLeave)
		})

		const invertedCursorItems = document.querySelectorAll('.inverted-cursor')

		invertedCursorItems.forEach(el => {
			el.addEventListener('mouseenter', handleInvertMouseEnter)
			el.addEventListener('mouseleave', handleInvertMouseLeave)
		})

		const forcedBlackCursorItems = document.querySelectorAll('.force-cursor-black')

		forcedBlackCursorItems.forEach(el => {
			el.addEventListener('mouseenter', handleForceCursorBlackEnter)
			el.addEventListener('mouseleave', handleForceCursorBlackLeave)
		})

		const forcedWhiteCursorItems = document.querySelectorAll('.force-cursor-white')

		forcedWhiteCursorItems.forEach(el => {
			el.addEventListener('mouseenter', handleForceCursorWhiteEnter)
			el.addEventListener('mouseleave', handleForceCursorWhiteLeave)
		})

		const dragCursorItems = document.querySelectorAll('.show-drag-cursor')

		dragCursorItems.forEach(el => {
			el.addEventListener('mouseenter', handleDragCursorEnter)
			el.addEventListener('mouseleave', handleDragCursorLeave)
		})
	}

	initDemo() {
		this.gridInner = document.querySelector(".grid__inner")

		this.cursorWrapper = document.querySelector(".cursor-wrapper")
		this.innerCursor = document.querySelector(".custom-cursor__inner")
		this.outerCursor = document.querySelector(".custom-cursor__outer")

		this.cursorWrapperBox = this.cursorWrapper.getBoundingClientRect()
		this.innerCursorBox = this.innerCursor.getBoundingClientRect()
		this.outerCursorBox = this.outerCursor.getBoundingClientRect()

		document.addEventListener("mousemove", e => {
			this.clientX = e.clientX
			this.clientY = e.clientY
		})

		const render = () => {
			TweenMax.set(this.cursorWrapper, {
				x: this.clientX,
				y: this.clientY
			})
			requestAnimationFrame(render)
		}
		requestAnimationFrame(render)

		this.fullCursorSize = 40
		this.enlargeCursorTween = TweenMax.to(this.outerCursor, 0.3, {
			backgroundColor: "transparent",
			width: this.fullCursorSize,
			height: this.fullCursorSize,
			ease: "power2.inOut",
			paused: true
		})

		this.mainNavHoverTween = TweenMax.to(this.outerCursor, 0.3, {
			backgroundColor: "#ffffff",
			opacity: 0.3,
			width: this.fullCursorSize,
			height: this.fullCursorSize,
			ease: "power2.inOut",
			paused: true
		})

		this.bumpCursorTween = TweenMax.to(this.outerCursor, 0.1, {
			scale: 0.7,
			paused: true,
			onComplete: () => {
				TweenMax.to(this.outerCursor, 0.2, {
					scale: 1,
					ease: "power2.inOut"
				})
			}
		})

		this.attachListeners()
	}
}